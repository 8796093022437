
































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFileInput from '@/components/VsFileInput/Index.vue'
import { AppModule } from '@/store/modules/app'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import {
    createImportFile,
    preprocessImport,
} from '@/api/consoleApi/imports'
import {
    ImportStrategyEnum,
    ImportStrategyFileDelimiterEnum,
    ImportStrategyFileQuoteEnum,
} from '@/api/consoleApi/dto/imports.dto'
import { get } from 'lodash'
import VsLoader from '@/components/VsLoader/Index.vue'
import axios from 'axios'
import VsTable, { TableHeader } from '@/components/VsTable/Index.vue'
import csv from 'csvtojson'

@Component({
    name: 'ImportFromFile',
    components: {
        VsContainer,
        VsFileInput,
        VsLoader,
        VsTable,
    },
})
export default class extends Vue {
    @Prop({ default: null }) list!: any
    private delimiter: ImportStrategyFileDelimiterEnum = ImportStrategyFileDelimiterEnum.auto
    private loading = false
    private skipFirstRow = true
    private file: any = ''
    private inputFileNameValue = ''
    private fileName = ''
    private errorRow = 0

    private preprocessData = {}
    private tempPreprocessInfo: any = {}
    private rowErrors: string[] = []
    private tableItems: any[] = []
    private tableHeaders: TableHeader[] = [{
        id: '',
        key: '',
        label: '',
        visible: true,
    }]

    $refs: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    get publicPath () {
        return process.env.BASE_URL
    }

    get universityUrl () {
        return AppModule.universityUrl
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get isSuppressionImport () {
        return this.$route.matched.find((el: any) => el.name === 'listImportIndexSuppression')
    }

    get hasMocks () {
        return AppModule.hasMocks
    }

    private loadingText (progressEvent?: any) {
        const loaded = progressEvent ? progressEvent.loaded : 0
        const total = progressEvent ? progressEvent.total : 0
        return `${this.$options?.filters?.formatBytes(loaded)} di ${this.$options?.filters?.formatBytes(total)}`
    }

    async createAndPreprocessImport () {
        const valid = await this.$refs.importForm.validate()
        if (!valid) return
        if (this.hasMocks) {
            if (this.rowErrors.length > 0) {
                this.$root.$vsToast({
                    heading: 'Attenzione!',
                    aspect: VsToastAspectEnum.alert,
                    message: 'Il file caricato contiene degli errori',
                    timeout: 3000,
                })
                return
            }
        }
        this.loading = true
        const importId = await this.createImport()
        if (importId) {
            try {
                const preprocess = await preprocessImport(importId)
                this.preprocessData = preprocess.data.data
                this.$emit('submit', this.preprocessData)
                this.loading = false
            } catch (e) {
                console.log(e)
                let heading = this.$t('lists.importContacts.fromFile.uploadError')
                if (axios.isAxiosError(e)) {
                    if (e.response?.status === 400) {
                        heading = this.$t('lists.importContacts.fromFile.errors.' + e.response.data.code)
                        if (e.response.data.code === 'ErrorOnParseCsvFile' && e.response.data.value) {
                            this.errorRow = e.response.data.value.lines
                        }
                    }
                }
                this.$root.$vsToast({
                    heading,
                    aspect: VsToastAspectEnum.alert,
                    timeout: 3000,
                })
            }
        }
        this.loading = false
    }

    private async createImport () {
        const toast = this.$root.$vsToast({
            heading: this.$t('lists.importContacts.fromFile.uploading'),
            aspect: VsToastAspectEnum.neutral,
        })
        try {
            const importData: any = this.prepareDataImport()

            const resp = await createImportFile(
                importData,
                {
                    onUploadProgress: (progressEvent: any) => {
                        toast.message = this.loadingText(progressEvent)
                        if (progressEvent.loaded === progressEvent.total) {
                            toast.dismiss(toast.id)
                        }
                    },
                },
            )
            return resp.data
        } catch (e) {
            toast.dismiss(toast.id)
            if (!get(e, 'response', '') && get(e, 'message', '') === 'Network Error') {
                this.$root.$vsToast({
                    heading: this.$t('lists.importContacts.fromFile.uploadErrorFileChanged'),
                    message: this.$t('lists.importContacts.fromFile.uploadErrorFileChangedMessage'),
                    aspect: VsToastAspectEnum.alert,
                    timeout: 5000,
                })
                return
            }
            const err = get(e, 'response.data.message.file', [])
            let message = this.$t('lists.importContacts.fromFile.uploadErrorGeneric')
            for (const el of err) {
                const size = el.match(/\d/g) || []
                if (size.length > 0 && el.startsWith('The file may not be greater than')) {
                    message = `${this.$t('lists.importContacts.fromFile.uploadErrorSize')} ${this.$options?.filters?.formatKbToMb(size.join(''))}`
                }
            }
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 413) {
                    message = this.$t('lists.importContacts.fromFile.uploadErrorServerSize')
                }
                if (e.response?.status === 400) {
                    if (e.response.data.message['import_strategy_settings.file']) {
                        message = this.$t('lists.importContacts.fromFile.errors["' + e.response.data.message['import_strategy_settings.file'][0] + '"]')
                    }
                }
            }

            this.$root.$vsToast({
                heading: this.$t('lists.importContacts.fromFile.uploadError'),
                aspect: VsToastAspectEnum.alert,
                message,
                timeout: 3000,
            })

            console.log(e)
        }
    }

    private prepareDataImport () {
        const data = new FormData()
        data.append('entity_id', this.isSuppressionImport ? '0' : this.listId)
        data.append('entity_type', this.isSuppressionImport ? 'suppression' : 'recipient')
        data.append('import_strategy', ImportStrategyEnum.file)
        data.append('import_strategy_settings[file]', this.file)
        data.append('import_strategy_settings[quote]', ImportStrategyFileQuoteEnum.double)
        data.append('import_strategy_settings[delimiter]', this.delimiter)
        data.append('import_strategy_settings[skip_first_row]', this.skipFirstRow ? '1' : '0')
        return data
    }

    @Watch('file', { deep: true })
    @Watch('skipFirstRow', { deep: true })
    @Watch('delimiter', { deep: true })
    async parseFile () {
        if (!this.hasMocks) return
        const valid = await this.$refs.importForm.validate()
        if (!valid) return
        if (!this.file) {
            this.reset()
            return
        }
        this.loading = true
        const reader = new FileReader()
        reader.onload = async (ev: any) => {
            const text = ev.target.result
            let strDelimiter: any = ','

            switch (this.delimiter) {
                case ImportStrategyFileDelimiterEnum.comma:
                    strDelimiter = ','
                    break
                case ImportStrategyFileDelimiterEnum.semicolon:
                    strDelimiter = ';'
                    break
                case ImportStrategyFileDelimiterEnum.auto:
                    strDelimiter = [',', ';', '\t']
                    break
                default:
                    strDelimiter = ','
                    break
            }

            const parsedFile: any = await csv({
                delimiter: strDelimiter,
                quote: '"',
                trim: true,
                noheader: true,
                output: 'csv',
            }).fromString(text)

            this.verifyRowsLength(parsedFile)

            const total = this.skipFirstRow ? parsedFile.length - 1 : parsedFile.length
            if (total <= 0) {
                this.$root.$vsToast({
                    heading: 'Attenzione!',
                    aspect: VsToastAspectEnum.alert,
                    message: 'Il file caricato non contiene righe',
                    timeout: 3000,
                })
            }
            const startIndex = this.skipFirstRow ? 1 : 0
            this.tempPreprocessInfo.header = this.generateHeader(parsedFile[0])
            this.tempPreprocessInfo.first_three_records = parsedFile.slice(startIndex, startIndex + 3)
            this.tempPreprocessInfo.total_records = total

            this.tableHeaders = this.tempPreprocessInfo.header.map((el: string, index: number) => {
                return {
                    id: index,
                    key: index + '',
                    label: el,
                    visible: true,
                }
            })
            this.tableItems = this.tempPreprocessInfo.first_three_records.map((el: any[]) => Object.assign({}, el))
            this.loading = false
        }
        reader.onerror = (e: any) => {
            this.rowErrors = ['Il file sorgente è stato modificato, riseleziona il file.']
            this.file = null
            this.loading = false
            this.reset()
        }
        reader.readAsText(this.file as unknown as Blob)
    }

    private verifyRowsLength (rows: any[]) {
        let length
        this.rowErrors = []
        for (const row in rows) {
            if (!length) length = rows[row].length
            if (rows[row].length !== length) {
                if (rows.length === parseInt(row) + 1 && rows[row].length === 1 && rows[row][0] === '') continue
                this.rowErrors.push(`Le colonne alla riga ${parseInt(row) + 1} sono ${rows[row].length} invece che ${length}.`)
            }
        }
        return this.rowErrors.length === 0
    }

    private generateHeader (row: string[]) {
        if (this.skipFirstRow) return row
        const count = row.length
        const header = []
        for (let i = 0; i < count; i++) {
            header.push(`field${i + 1}`)
        }
        return header
    }

    private reset () {
        this.tempPreprocessInfo.header = []
        this.tempPreprocessInfo.first_three_records = []
        this.tempPreprocessInfo.total_records = 0
    }
}
